import { createRef } from "react";
import { Button, Container, Form } from "react-bootstrap";
import { LoginStatus, useLoginOutletContext } from "../../components/LoginManager";

/**
 * User login component.
 */
const Login = () => {
    const { login_state, login_handler } = useLoginOutletContext();
    const email_ref = createRef<HTMLInputElement>();
    const password_ref = createRef<HTMLInputElement>();

    const logged_out = login_state.status === LoginStatus.LOGGED_OUT;

    const on_submit = () => {
        if (email_ref.current === null || password_ref.current === null) return;
        login_handler(email_ref.current?.value, password_ref.current?.value);
    }

    return (
        <div style={{
            display: "block",
            width: "25vw",
            marginLeft: "auto",
            marginRight: "auto"
        }}>
            <Form.Control
                ref={email_ref} 
                placeholder="Email" 
                type="email"
                disabled={!logged_out}
                style={{marginBottom: "10px"}}
            />
            <Form.Control 
                ref={password_ref} 
                placeholder="Password" 
                type="password"
                disabled={!logged_out}
                style={{marginBottom: "10px"}}
            />
            <Button 
                disabled={!logged_out} 
                onClick={on_submit}
                style={{width: "100%"}}
            >
                Login
            </Button>
            <br/>
        </div>
    );
}

export default Login;