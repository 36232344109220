import { Link, useNavigate } from 'react-router-dom';
import Login from './Login';
import { useEffect } from 'react';
import { LoginStatus, useLoginOutletContext } from '../../components/LoginManager';
import { Stack } from 'react-bootstrap';
import "./LandingPage.css";

const LandingPage = () => {
    const navigate = useNavigate();
    const { login_state, login_handler } = useLoginOutletContext();

    useEffect(() => {
        if (login_state.status === LoginStatus.LOGGED_IN)
            navigate("/requests");
    }, [login_state]);

    return (
        <div>
            <Stack direction="vertical" style={{textAlign: "center"}}>
                <h1 className="landing-page-header">NoteWorthy</h1>
                <br/>
                <Login/>
                <Link to="/signup">or sign up here</Link>
                <br/>
                <h4 className="landing-page-quote">Clinical Excellence through Excellent Notes</h4>
            </Stack>
        </div>
    );
}

export default LandingPage;