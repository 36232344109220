import { NoteworthyApiClient } from "../NoteworthyApiClient";
import { Note } from "../objects/Note";
import { RequestInfo } from "../objects/RequestInfo";
import { Review } from "../objects/Review";

const API_URL = "https://udmpwyq302.execute-api.us-east-1.amazonaws.com";
const REVIEWS_PATH = "/reviews";
const SUBMIT_NOTES_PATH = "/submit-notes";
const USER_REQUESTS_PATH = "/users-requests";

export class ConcreteClient implements NoteworthyApiClient {
    async getUserRequests(user_id: string): Promise<RequestInfo[]>
    {
        let response = await fetch(API_URL + USER_REQUESTS_PATH, {
            method: "POST",
            body: JSON.stringify({user_id: user_id})
        });

        const response_body = await response.json();

        if (!response.ok) {
            throw new Error(response_body["message"]);
        }

        return response_body.requests
            .map((info_string: string) => JSON.parse(info_string));
    }
    
    async getRequestReviews(request_id: number): Promise<Review[]> {
        let response = await fetch(API_URL + REVIEWS_PATH, {
            method: "POST",
            body: JSON.stringify({request_id: request_id})
        });

        let response_body = await response.json();

        if (!response.ok) {
            throw new Error(response_body["message"]);
        }

        return response_body.reviews
            .map((info_string: string) => JSON.parse(info_string));
    }

    async submitNotes(user_id: string, submitted_notes: Note[]): Promise<number> {
        let notes = submitted_notes.map(note => {return {
            note_text: note.note_text,
            note_id: note.note_id,
            metadata: note.metadata,
            owner_id: user_id
        }});

        let response = await fetch(API_URL + SUBMIT_NOTES_PATH, {
            method: "POST",
            body: JSON.stringify({user_id: user_id, notes: notes})
        });

        let response_body = await response.json();

        if (!response.ok) {
            throw new Error(response_body["message"]);
        }

        return response_body;
    }

}