import { NoteworthyApiClient } from "../NoteworthyApiClient";
import { Note } from "../objects/Note";
import { RequestInfo } from "../objects/RequestInfo";
import { CriteriaRating, Review } from "../objects/Review";

const LOREM_IPSUM = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas eget luctus magna. Nulla turpis quam, pretium ac mauris nec, egestas dapibus ligula. Aenean eu lobortis elit, sed maximus tortor.\nDuis porttitor dui non enim consectetur molestie. Nunc blandit tortor diam, quis vestibulum ipsum rutrum at.\nDonec imperdiet lobortis ex, sed vehicula massa pharetra sed. Morbi sit amet finibus leo, rhoncus imperdiet dui. Praesent malesuada sem eu ante varius pellentesque. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; In augue odio, pellentesque id lacus ut, lobortis viverra dui. Ut dapibus, eros ac tristique eleifend, enim augue venenatis tortor, ac congue odio enim a felis. Maecenas ut iaculis sapien. Sed vel ante feugiat sem malesuada semper."

/**
 * Client which simulates interaction with the Noteworthy backend.
 */
export class TestClient implements NoteworthyApiClient {
    static requests: RequestInfo[] = [];
    static reviews: { [key: number]: Review[] } = {};
    static current_request = 0;

    async getUserRequests(user_id: string): Promise<RequestInfo[]> {
        await new Promise(f => setTimeout(f, 5000));

        let results: RequestInfo[] = []
        for (let request of TestClient.requests) {
            if (request.request_user_id === user_id) {
                results.push(request);
            }
        }

        return results;
    }

    async getRequestReviews(request_id: number): Promise<Review[]> {
        await new Promise(f => setTimeout(f, 5000));
        return TestClient.reviews[request_id];
    }

    async submitNotes(user_id: string, notes: Note[]): Promise<number> {
        await new Promise(f => setTimeout(f, 5000));

        let request_id = ++TestClient.current_request;
        let reviews: Review[] = [];

        for (let note of notes.slice(0, Math.floor(Math.random() * notes.length))) {
            let ratings: CriteriaRating[] = [];
            for (let i = 1; i < 6; ++i) {
                ratings.push({
                    criteria_number: i,
                    rating: Math.floor(Math.random() * 10),
                    feedback: LOREM_IPSUM
                });
            }

            reviews.push({
                note_id: note.note_id,
                ratings: ratings
            });
        }

        TestClient.requests.push(
            {
                request_id,
                request_user_id: user_id,
                notes_reviewed: (reviews.length === 0) ? undefined : reviews.length,
                review_time: (reviews.length === 0) ? undefined : "2024-03-14 9:56 AM",
                submission_time: "2024-03-14 9:56 AM",
                size: notes.length,
                status_string: (reviews.length === 0) 
                    ? "pending" 
                    : (reviews.length === notes.length) 
                        ? "done" 
                        : "reviewing",
            }
        );
        TestClient.reviews[request_id] = reviews;
        return request_id;
    }
}